import React from "react"

const Audio = ({ link,style}) => (

<audio controls autoPlay muted>
      <source src={link} type="audio/mpeg"/>
    <em>Your browser does not support the audio element.</em>
</audio>

)

export default Audio
