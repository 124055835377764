import { Link } from "gatsby"
import React from 'react';
import { graphql } from 'gatsby';

import BOOK from "../images/book.svg"
import SEO from '../components/seo'
import Img from "gatsby-image"
import Layout from "../components/layout";
import Audio from '../components/audio';

const AVideo = ( { video } ) => {
	const arr = video.split("/");
	const suffix = arr[ arr.length - 1 ];
	
	return (
	        <div style={{ margin:`3em auto`,display:`block`,width:`86%`,paddingBottom:`50%`,overflow:`hidden`,position:`relative` }}>
         <iframe style={{width:`100%`,height:`100%`,position:`absolute`}}
		src={`https://www.youtube.com/embed/${ suffix }`} 
		title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
        </div>
	);
}


const PostTemplate = ({ data }) => {
  const { markdownRemark } = data;
  const { frontmatter, html, excerpt, rawMarkdownBody } = markdownRemark;

    const url = data.site.siteMetadata.url + markdownRemark.fields.slug;

    let img = null;
    let imgW = 0;
    let imgH = 0;

    if (frontmatter.icon) {
        img = frontmatter.icon.childImageSharp.fluid.src;
        imgW = frontmatter.icon.childImageSharp.fluid.src.presentationWidth;
        imgH = frontmatter.icon.childImageSharp.fluid.src.presentationHeight;
    }

  const tags = (frontmatter.tags) ? frontmatter.tags : "Post Writting Poet";

  //TODO: website , breadcrumbs
  const schema = {
        "@context": "https://schema.org",
        "@graph": [
            {
                "@type": "CreativeWork",
                "@id": url,
                "url": url,
                "author": {
                    "@type": "Person",
                    "name": "Christian Lacdael",
                    "url": data.site.siteMetadata.url+"/christian-lacdael",
                },
                "copyrightHolder": {
                    "@type": "Person",
                    "name": "Christian Lacdael",
                    "url": data.site.siteMetadata.url+"/christian-lacdael",
                },
                "creator": {
                    "@type": "Person",
                    "name": "Christian Lacdael",
                    "url": data.site.siteMetadata.url+"/christian-lacdael",
                },
                "description": excerpt,
                "genre":"poetry",
                "inLanguage":"en",
                "name": "Excerpt | "+frontmatter.title,
                "keywords": tags.split(" "),
           }
        ]
    };

//text
if (img) schema["image"] = {"@type":"ImageObject","url":(url+img),"width":imgW,"height":imgH};

return (
    <Layout>
      <SEO title={ frontmatter.title }  audio={frontmatter.audio}  description={excerpt} tags={tags} date={ frontmatter.date } image={img} what="article" schemaMarkup={ schema } />
        <ul className="breadcrumb">
        <li><Link to="/">Home</Link></li>
       <li><Link to="/excerpts">Excerpts</Link></li>
        <li>{frontmatter.title}</li>
        </ul>
      <section style={{margin:0.5+`em`,overflow:`hidden`}}>
      { frontmatter.book && < Link to={ "/books/" + frontmatter.book } ><img id="book" alt="book" src={BOOK}  style={{ float:`right`,width:`5em`,border: `4px solid var(--emphasis)`,borderRadius: `0.5em` }} /></Link> }
      <h1>{frontmatter.title}</h1>
        { frontmatter.video && <AVideo video={ frontmatter.video } /> }
        { frontmatter.audio && <Audio link={frontmatter.audio.publicURL} style={{maxWidth:`30em`}} /> }
        <div dangerouslySetInnerHTML={{ __html: html }} />
        { frontmatter.icon && <div><Img fluid={frontmatter.icon.childImageSharp.fluid} alt={frontmatter.title} style={{maxWidth:`30em`, margin:"auto"}} /></div> }

	<em>{frontmatter.date}</em>
      </section>
    </Layout>
  );
};

export default PostTemplate;

export const pageQuery = graphql`
  query($slug: String!) {
  	site {
        siteMetadata {
            url
        }
    }
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      excerpt(pruneLength: 250)
      rawMarkdownBody
      fields{
        slug
      }
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        title
        tags
        book
	video
        icon {
            childImageSharp {
                fluid {
                    ...GatsbyImageSharpFluid
                    src
                    presentationHeight
                    presentationWidth
                }
            }
        }
    }
  }
}`;
